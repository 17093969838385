














































































































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import GetGasTokenToUseInput from '@/components/getGas/GetGasTokenToUseInput.vue'
import GetGasTokenToReceiveInput from '@/components/getGas/GetGasTokenToReceiveInput.vue'
import GetGasPoweredBy from '@/components/getGas/GetGasPoweredBy.vue'
import BigNumber from 'bignumber.js'

@Component({
  components: {
    GetGasPoweredBy,
    GetGasTokenToUseInput,
    GetGasTokenToReceiveInput,
  },
})
export default class GetGasFormStep extends MixinScreenSize {
  @Prop({type: String, required: true}) amountToReceive!: string
  @Prop({type: String, required: true}) neoOwned!: string
  @Prop({type: String, required: true}) amountToUse!: string

  computedAmountToUse: string = this.amountToUse

  get isProceedDisabled() {
    return (
      !this.computedAmountToUse.trim() ||
      this.computedAmountToUse.trim() === '0' ||
      new BigNumber(this.computedAmountToUse).gt(new BigNumber(this.neoOwned))
    )
  }

  mounted() {
    const element = document.querySelector(
      '.get-gas-form-step__content'
    ) as HTMLElement

    setTimeout(() => {
      this.addScrollShadow(element)
      this.addScrool(element)
    }, 300)

    element.addEventListener('scroll', event => {
      this.addScrollShadow(event.target as HTMLElement)
    })

    window.addEventListener('resize', () => {
      this.addScrool(element)
      this.addScrollShadow(element)
    })
  }

  handleAmountToUseInput(val: string): void {
    this.computedAmountToUse = val
    this.$emit('update:amountToUse', val)
  }

  handleProceedClick(): void {
    this.$emit('proceedClick')
  }

  private addScrollShadow(target: HTMLElement) {
    if (this.isMobile) return

    const {scrollTop} = target
    const headerElement = document.querySelector('.get-gas-form-step__header')
    const footerElement = document.querySelector('.get-gas-form-step__footer')

    if (scrollTop > 0) {
      headerElement?.classList.add('shadow-bottom-1/4')
    } else {
      headerElement?.classList.remove('shadow-bottom-1/4')
    }

    const maxScrollTop = target.scrollHeight - target.clientHeight
    if (scrollTop < maxScrollTop) {
      footerElement?.classList.add('shadow-top-1/4')
    } else {
      footerElement?.classList.remove('shadow-top-1/4')
    }
  }

  private addScrool(target: HTMLElement) {
    if (target.scrollHeight > target.clientHeight) {
      target.classList.add('overflow-y-scroll')
    } else {
      target.classList.remove('overflow-y-scroll')
    }
  }
}
